import request from '@/utils/request'
// 我的优惠券 - 未使用
export function couponUnused(params) {
  return request({
    url: 'personal/Coupon/unused',
    method: 'get',
    params: params
  })
}
// 我的优惠券 - 已过期
export function couponExpires(params) {
  return request({
    url: 'personal/Coupon/expires',
    method: 'get',
    params: params
  })
}

// 我的优惠券 - 已使用
export function couponUsed(params) {
  return request({
    url: 'personal/Coupon/used',
    method: 'get',
    params: params
  })
}
